const GalleryCard = (props) => {
    return (
        <>
            <div className="col-md-4 col-10 mx-auto">
                <div className="card">
                    <img
                        src={props.imgsrc}
                        className="card-img-top img-style"
                        alt={props.imgsrc}
                    />
                </div>
            </div>
        </>
    );
};

export default GalleryCard;
