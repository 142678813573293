import Card from "./GalleryCard";
import Sdata from "./GalleryData";
import "../styles/Gallery.css";

const Products = () => {
    const header = {
        subHeading: "Our Manufacturing Plant",
        text: "These are the pictures of our plant",
    };

    return (
        <>
            <div className="Main-container-service">
                <div className="services">
                    <div className="container">
                        <div className="services__header">
                            <div className="common">
                                <h1 className="mainHeader">{header.subHeading}</h1>
                                <p className="mainContent">{header.text}</p>
                                <div className="commonBorder"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mb-5">
                    <div className="row">
                        <div className="col-10 mx-auto">
                            <div className="row gy-4">
                                {Sdata.map((val, ind) => {
                                    return (
                                        <>
                                            <Card
                                                imgsrc={val.imgsrc}
                                                title={val.title}
                                                description={val.description}
                                            />
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Products;
