import React from "react";
import Header from "./component/Header";
import Aboutus from "./component/Aboutus";
import Contactus from "./component/Contactus";
import Products from "./component/Product/Products";
import Footer from "./component/Footer";
import Home from "./component/Home";
import Gallery from "./component/Gallery/Gallery";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="products" element={<Products />} />
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="contactus" element={<Contactus />} />
          <Route path="gallery" element={<Gallery />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;