import "./styles/Home.css";
import Aboutus from "./Aboutus";
import Contactus from "./Contactus";
import Products from "./Product/Products";

const Home = () => {
  return (
    <>
      <section id="home" className="home">
        <div className="content">
          <h1 className="banner">Vin Power Electro Product</h1>
          <h3 className="slogan">Heavy Duty Wires & Cables</h3>
        </div>
      </section>
      <Products />
      <Aboutus />
      <Contactus />
    </>
  );
};

export default Home;