import Carousel from "react-elastic-carousel";
import './styles/Aboutus.css';
import certificate_1 from '../Assets/certificate_1.png';
import certificate_2 from '../Assets/certificate_2.jpg';
import certificate_3 from '../Assets/certificate_3.jpg';
import certificate_4 from '../Assets/certificate_4.jpg';
import certificate_5 from '../Assets/certificate_5.jpg';
import AboutusImg from '../Assets/factory-1.jpg';

const Aboutus = () => {
  const header = {
    subHeading: "Our Certifications",
    text: "These are the approvals & certificates we got",
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  const items = [
    { id: 1, image: certificate_1 },
    { id: 2, image: certificate_2 },
    { id: 3, image: certificate_3 },
    { id: 4, image: certificate_4 },
    { id: 5, image: certificate_5 },
  ];
  return (
    <div>
      <section id="mu-about">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mu-about-area">

                <div className="row">
                  <div className="col-md-12">
                    <div className="mu-title">
                      <h2 className="mainHeader">Who we are</h2>
                      <p>Excellence is our habit & quality is our work.</p>
                      <div className="commonBorder"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mu-about-left" style={{ marginBottom: '20px' }}>
                      <img className="" src={AboutusImg} alt="img" style={{
                        width: '100%'
                      }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mu-about-right">
                      <ul>
                        <li>
                          <h3>Our Mission</h3>
                          <p>To provide best experience through success built on performance. </p>
                        </li>
                        <li>
                          <h3>Our Vision</h3>
                          <p>To be sought out as the recognized and trusted firm in the industry and community, retaining, developing and attracting the best in commited manufacturing leadership ,to derive deliberate and intelligent growth of the company.</p>
                        </li>
                        <li>
                          <h3>Our Values</h3>
                          <p> Clients trust us to build things that work and we take that seriously. Our team will overcome obstacles, find solutions and deliver exceptional results.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="About-us">
        <div className="services">
          <div className="container">
            <div className="services__header">
              <div className="common">
                <h1 className="mainHeader">{header.subHeading}</h1>
                <p className="mainContent">{header.text}</p>
                <div className="commonBorder"></div>
              </div>
            </div>
          </div>
        </div>
        <Carousel enableAutoPlay autoPlaySpeed={1500} breakPoints={breakPoints} >
          {items.map((item) => (
            <div key={item.id}><img src={item.image} alt="certificates" /></div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Aboutus;
