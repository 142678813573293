import "./styles/Contact.css";

const Contactus = () => {
  return (
    <>
      <div className="contact-main">
        <div className="row main-Header">
          <div className="col-md-12">
            <div className="mu-title">
              <h1>Contact us</h1>
              <h6>Feel free to reach us</h6>
              <div className="commonBorder"></div>
            </div>
          </div>
        </div>
        <div className="contact_footer" style={{ display: "flex" }}>
          <div className="contact_info mt-4">
            <div className="conatiner-fluid">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 all_details">
                  <div className="contact_info_item d-flex justify-content-center align-items-center p-3 details address">
                    <div>
                      <i
                        className="fa fa-map-marker fa-2x px-3"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="contact_info_content">
                      <div className="contact_info_title">Our Address</div>
                      <div className="contact_info_text">
                        H-413B, 413A, 412B - Sarna Doongar Industrial Area, Jhothwara Ext., Jaipur, India, 302012
                      </div>
                    </div>
                  </div>

                  {/* phone number */}
                  <div className="contact_info_item d-flex justify-content-center align-items-center p-3 details call">
                    <i
                      className="fa fa-phone fa-2x px-3"
                      aria-hidden="true"
                    ></i>
                    <div className="contact_info_content">
                      <div className="contact_info_title">CALL US</div>
                      <div>
                        <a
                          className="contact_info_text"
                          href="tel:+9 9351912048"
                        >
                          +91 9351912048
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* email number */}
                  <div className="contact_info_item d-flex justify-content-center align-items-center p-3 details">
                    <i
                      className="fa fa-envelope fa-2x px-3"
                      aria-hidden="true"
                    ></i>
                    <div className="contact_info_content">
                      <div className="contact_info_title">Email Us</div>
                      <div>
                        <a
                          className="contact_info_text"
                          href="mailto:vinpowerelectroproduct@gmail.com"
                        >
                          vinpowerelectroproduct@gmail.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contactus;
