import factory1 from "../../Assets/factory-2.jpg";
import factory2 from "../../Assets/factory-3.jpg";
import factory3 from "../../Assets/factory-4.jpg";
import factory4 from "../../Assets/factory-5.jpg";
const GalleryData = [
    {
        id: 1,
        imgsrc: `${factory1}`,
    },
    {
        id: 2,
        imgsrc: `${factory2}`,
    },
    {
        id: 3,
        imgsrc: `${factory3}`,
    },
    {
        id: 4,
        imgsrc: `${factory4}`,
    },
    {
        id: 5,
        imgsrc: `${factory1}`,
    },
    {
        id: 6,
        imgsrc: `${factory2}`,
    },
];

export default GalleryData;
