import "./styles/Footer.css";

const Footer = () => {
  let date = new Date();
  const year = date.getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="footer-text">
          ©️ {year} Copyright | Vin Power Electro Product
        </div>
      </footer>
    </>
  );
};

export default Footer;
