import product1 from "../../Assets/Conductors_1.jpg";
import product2 from "../../Assets/Conductors_1.jpg";
import product3 from "../../Assets/Conductors_1.jpg";
import product4 from "../../Assets/Conductors_1.jpg";
import product5 from "../../Assets/Conductors_1.jpg";
import product6 from "../../Assets/Conductors_1.jpg";
const Sdata = [
  {
    id: 1,
    imgsrc: `${product1}`,
    title: "Conductor Wire",
    description: "Aluminium wire drawing",
  },
  {
    id: 2,
    imgsrc: `${product2}`,
    title: "Conductor Wire",
    description: "Aluminium wire drawing",
  },
  {
    id: 3,
    imgsrc: `${product3}`,
    title: "Conductor Wire",
    description: "Aluminium wire drawing",
  },
  {
    id: 4,
    imgsrc: `${product4}`,
    title: "Conductor Wire",
    description: "Aluminium wire drawing",
  },
  {
    id: 5,
    imgsrc: `${product5}`,
    title: "Conductor Wire",
    description: "Aluminium wire drawing",
  },
  {
    id: 6,
    imgsrc: `${product6}`,
    title: "Conductor Wire",
    description: "Aluminium wire drawing",
  },
];

export default Sdata;
